
  import { Task, TaskAssignee, TaskStatus } from "@/store/modules/task.store";
  import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";

  interface TaskCategoryGroup {
    [key: string]: Task[];
  }

  @Component({
    components: {
      TaskRow: () => import("@/components/task/row.vue"),
      AddTask: () => import("@/views/auth/dossier/tabs/parts/tasks/add-task.vue"),
      FormCheckbox: () => import("@/components/forms/checkbox.vue"),
    },
  })
  export default class TaskGroupPart extends Vue {
    @Prop({ required: true }) readonly isOpen!: boolean;

    @Prop({ default: false }) readonly isDossier!: boolean;

    @Prop({ default: false }) readonly bulkUpdating!: boolean;

    @Prop({ default: null }) readonly defaultAssignee!: Account | null;

    @Prop({ required: true }) readonly groupName!: string;

    @Prop({ required: true }) readonly activityId!: number;

    @Prop({ required: true }) readonly taskGroup!: TaskCategoryGroup | Task[];

    @Prop({ required: true }) readonly users!: Array<{ first_name: string; last_name: string }>;

    @Prop({ required: true }) readonly documents!: Array<{ id: number }>;

    @Prop({ required: true }) readonly addTaskPlaceholder!: string;

    @Prop({ default: () => [] }) readonly checkedTasks!: number[];

    @Prop({ default: () => [] }) readonly hoveringTasks!: number[];

    @Prop({ default: null }) readonly hoveringType!: string | null;

    groupChecked: string[] = [];

    phaseChecked = false;

    get isTaskCateoryGroup(): boolean {
      return typeof this.taskGroup === "object" && !Array.isArray(this.taskGroup);
    }

    get groupProgress(): number {
      let total = 0;
      let tasks = 0;

      Object.values(this.localTaskGroups).forEach((t) => {
        total += t.length;
        tasks += t.filter((t: Task) => t.status === "DONE" || t.status === "NA").length;
      });

      return (tasks / total) * 100;
    }

    get localTaskGroups(): { [key: string]: Task[] } {
      if (this.isTaskCateoryGroup) {
        return this.taskGroup as { [key: string]: Task[] };
      }

      return { "": this.taskGroup as Task[] };
    }

    get lastOrdinal(): number {
      const lastOrdinal = Object.values(this.localTaskGroups).reduce((acc, val) => {
        const last = val.reduce((acc, val) => {
          if (val.ordinal > acc) {
            return val.ordinal;
          }
          return acc;
        }, 0);
        if (last > acc) {
          return last;
        }
        return acc;
      }, 0);

      return lastOrdinal + 1;
    }

    clearGroupChecked() {
      this.groupChecked = [];
    }

    toggleCategoryChecked(category: string | number) {

      if (this.groupChecked.includes(`${category}`)) {
        this.groupChecked = this.groupChecked.filter((c) => c !== `${category}`);
      } else {
        this.groupChecked.push(`${category}`);
      }
    }

    @Emit("deleted")
    handleTaskDeleted(taskId: number) {}

    @Emit("status-changed")
    handleStatusChanged(payload: { status: TaskStatus; id: number }) {
      return payload;
    }

    @Emit("date-changed")
    handleDateChanged(payload: { deadline: string; id: number }) {
      return payload;
    }

    @Emit("assignee-changed")
    handleAssigneeChanged(payload: { assignees: { id: number; type: "user" | "seller" }[]; id: number; seller_assigned_to: TaskAssignee[]; assigned_to: TaskAssignee[] }) {
      return payload;
    }

    @Emit("document-changed")
    handleDocumentChanged(payload: { documents: number[]; task: Task }) {
      return payload;
    }

    @Emit("open")
    toggleGroup() {
      return this.groupName;
    }

    @Emit("task-check-changed")
    handleTaskCheckChanged(taskId: number) {
      return taskId;
    }

    @Emit("bulk-check-tasks")
    bulkCheckTasks(taskIds: number[]) {
      return taskIds;
    }

    @Emit("bulk-uncheck-tasks")
    bulkUncheckTasks(taskIds: number[]) {
      return taskIds;
    }

    @Emit("mouseover")
    mouseover(payload: { taskId: number; type: string }) {
      return payload;
    }

    @Emit("mouseleave")
    mouseleave(payload: { taskId: number; type: string }) {
      return payload;
    }

    get computedGroupChecked() {
      return JSON.parse(JSON.stringify(this.groupChecked));
    }

    @Watch("computedGroupChecked")
    handlePhaseChecked(newValue: boolean) {
      if (newValue) {
        this.bulkCheckTasks(
          Object.values(this.taskGroup)
            .filter(Boolean)
            .map((t) => t.id),
        );
      } else {
        this.bulkUncheckTasks(
          Object.values(this.taskGroup)
            .filter(Boolean)
            .map((t) => t.id),
        );
      }
    }

    @Watch("computedGroupChecked")
    handleGroupCheckedChanged(newValue: string[], oldValue: string[]) {
      const newlyAddeddValue = newValue.filter((x) => !oldValue.includes(x));
      const removedValue = oldValue.filter((x) => !newValue.includes(x));

      // Enable these
      newlyAddeddValue.forEach((element) => {
        this.bulkCheckTasks(this.localTaskGroups[element].map((t) => t.id));
      });

      // disable these
      removedValue.forEach((element) => {
        this.bulkUncheckTasks(this.localTaskGroups[element].map((t) => t.id));
      });
    }
  }
